import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import {
  DiAngularSimple,
  DiReact,
  DiJavascript,
  DiJqueryLogo,
  DiHtml5,
  DiCss3,
  DiBootstrap,
  DiMaterializecss,
  DiPhp,
  DiMysql,
  DiDotnet,
  DiMsqlServer,
  DiGithubBadge,
  DiBitbucket,
  DiCodeigniter,
  DiLaravel,
  DiDrupal,
  DiWordpress,
} from "react-icons/di"
import { IconContext } from "react-icons"

const IndexPage = () => (
  <>
    <Helmet>
      <meta charset="utf-8" />
      <title>Marielle Monceller | Web Developer</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />
      <link rel="stylesheet" href="assets/css/main.css" />
    </Helmet>
    <div className="is-preload">
      <nav id="nav">
        <ul className="container">
          <li>
            <a href="#top">About</a>
          </li>
          <li>
            <a href="#skills">Skills</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>

      <article id="top" className="wrapper style1">
        <div className="container">
          <div className="row">
            <div className="col-4 col-5-large col-12-medium">
              <span className="image fit">
                <img src="images/profile.jpg" alt="" />
              </span>
            </div>
            <div className="col-8 col-7-large col-12-medium">
              <header>
                <h1>
                  <strong>Marielle Monceller</strong>
                </h1>
              </header>
              <p>
                I'm a seasoned <strong>Web Developer</strong> based in the
                Philippines. I have 10 years of working experience. As a natural
                problem solver, I thrive in creating remarkably functional web
                apps and software that provide convenience and contribute to a
                better world.
              </p>
              <p>
                My history with computers and technology started as early as six
                years old. My inquisitive mind led me to where I am now and it's
                no wonder why I'm always the go-to tech support among my
                friends.
              </p>
              <p>
                When not coding or helping with technical stuff, I devote my
                hours to my family and spend my free time traveling, doing some
                amateur photography, making music, or learning something new.
              </p>
              <a href="#skills" className="button large scrolly">
                See my skills
              </a>
            </div>
          </div>
        </div>
      </article>

      <article id="skills" className="wrapper style2">
        <div className="container">
          <header>
            <h2>Technology Stack</h2>
            <p>Below are all the tools I've used so far in my projects.</p>
          </header>
          <div className="row aln-center">
            <div className="col-4 col-6-medium col-12-small">
              <section className="box style1">
                <h3>JavaScript</h3>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(221,3,48)" },
                    }}
                  >
                    <DiAngularSimple />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(83,193,222)" },
                    }}
                  >
                    <DiReact />
                  </IconContext.Provider>
                </div>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(247,223,30)" },
                    }}
                  >
                    <DiJavascript />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(31,120,170)" },
                    }}
                  >
                    <DiJqueryLogo />
                  </IconContext.Provider>
                </div>
              </section>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <section className="box style1">
                <h3>Front-end</h3>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(240,101,41)" },
                    }}
                  >
                    <DiHtml5 />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(1,112,186)" },
                    }}
                  >
                    <DiCss3 />
                  </IconContext.Provider>
                </div>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(85,58,125)" },
                    }}
                  >
                    <DiBootstrap />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(229,109,116)" },
                    }}
                  >
                    <DiMaterializecss />
                  </IconContext.Provider>
                </div>
              </section>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <section className="box style1">
                <h3>Back-end</h3>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(137,147,190)" },
                    }}
                  >
                    <DiPhp />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(47,134,156)" },
                    }}
                  >
                    <DiMysql />
                  </IconContext.Provider>
                </div>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(17,108,163)" },
                    }}
                  >
                    <DiDotnet />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(128,44,44)" },
                    }}
                  >
                    <DiMsqlServer />
                  </IconContext.Provider>
                </div>
              </section>
            </div>
          </div>
          <span>&nbsp;</span>
          <div className="row aln-center">
            <div className="col-4 col-6-medium col-12-small">
              <section className="box style1">
                <h3>Version Control</h3>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(33,31,31)" },
                    }}
                  >
                    <DiGithubBadge />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(38,132,255)" },
                    }}
                  >
                    <DiBitbucket />
                  </IconContext.Provider>
                </div>
              </section>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <section className="box style1">
                <h3>MVC Frameworks</h3>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(238,67,35)" },
                    }}
                  >
                    <DiCodeigniter />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(240,83,64)" },
                    }}
                  >
                    <DiLaravel />
                  </IconContext.Provider>
                </div>
              </section>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <section className="box style1">
                <h3>CMS</h3>
                <div>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(0,156,222)" },
                    }}
                  >
                    <DiDrupal />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: { fontSize: "80px", color: "rgb(81,86,90)" },
                    }}
                  >
                    <DiWordpress />
                  </IconContext.Provider>
                </div>
              </section>
            </div>
          </div>
          <footer>
            <p>Want to know how I've used these?</p>
            <a href="#portfolio" className="button large scrolly">
              See some of my works
            </a>
          </footer>
        </div>
      </article>

      <article id="portfolio" className="wrapper style3">
        <div className="container">
          <header>
            <h2>Projects</h2>
            <p>Here's where I've applied my skills to.</p>
          </header>
          <div className="row">
            <div className="col-4 col-6-medium col-12-small">
              <article className="box style2">
                <a href="https://nullreferer.com/?https://www.ecoarttravel.com/en" rel="external nofollow" className="image featured">
                  <img src="images/project1.png" alt="" />
                </a>
                <h3>
                <a href="https://nullreferer.com/?https://www.ecoarttravel.com/en" rel="external nofollow">EcoArt Travel</a>
                </h3>
                <p>Laravel</p>
              </article>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <article className="box style2">
                <a href="https://nullreferer.com/?https://www.tapclicks.com/" rel="external nofollow" className="image featured">
                  <img src="images/project2.png" alt="" />
                </a>
                <h3>
                <a href="https://nullreferer.com/?https://www.tapclicks.com/" rel="external nofollow">TapAnalytics</a>
                </h3>
                <p>Drupal</p>
              </article>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <article className="box style2">
                <a href="#" className="image featured">
                  <img src="images/project3.png" alt="" />
                </a>
                <h3>
                  <a href="#">DHS Australian Workwear</a>
                </h3>
                <p>Laravel</p>
              </article>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <article className="box style2">
                <a href="https://nullreferer.com/?https://www.yourhealthandlongevity.com/" rel="external nofollow" className="image featured">
                  <img src="images/project4.png" alt="" />
                </a>
                <h3>
                <a href="https://nullreferer.com/?https://www.yourhealthandlongevity.com/" rel="external nofollow">HolliBalance Admin Panel</a>
                </h3>
                <p>Laravel, Wordpress</p>
              </article>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <article className="box style2">
                <a href="https://nullreferer.com/?https://www.zenerchiretreat.com/" rel="external nofollow" className="image featured">
                  <img src="images/project5.png" alt="" />
                </a>
                <h3>
                  <a href="https://nullreferer.com/?https://www.zenerchiretreat.com/" rel="external nofollow">Zenerchi Retreat</a>
                </h3>
                <p>Wordpress (custom plugin)</p>
              </article>
            </div>
            <div className="col-4 col-6-medium col-12-small">
              <article className="box style2">
                <a href="#" className="image featured">
                  <img src="images/project6.png" alt="" />
                </a>
                <h3>
                  <a href="#">Dino Drawing</a>
                </h3>
                <p>React</p>
              </article>
            </div>
          </div>
          <footer>
            <p>Want to see in my skills in action?</p>
            <a href="#contact" className="button large scrolly">
              Work on a project with me
            </a>
          </footer>
        </div>
      </article>

      <article id="contact" className="wrapper style4">
        <div className="container medium">
          <header>
            <h2>Let me help you develop your app</h2>
            <p>
              I'm mostly available for freelance projects unless stated here.
            </p>
          </header>
          <div className="row">
            <div className="col-12">
              <form
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="contact"
              >
                <div className="row">
                  <div className="col-6 col-12-small">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-6 col-12-small">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <ul className="actions">
                      <li>
                        <input type="submit" value="Send Message" />
                      </li>
                      <li>
                        <input
                          type="reset"
                          value="Clear Form"
                          className="alt"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12">
              <hr />
              <h3>Find me on ...</h3>
              <ul className="social">
                <li>
                  <a
                    href="https://ph.linkedin.com/in/mariellemonceller"
                    className="icon brands fa-linkedin-in"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/ayel07"
                    className="icon brands fa-github"
                  >
                    <span className="label">Github</span>
                  </a>
                </li>
              </ul>
              <hr />
            </div>
          </div>
          <footer>
            <ul id="copyright">
              <li>&copy; 2019 Marielle Monceller. All rights reserved.</li>
            </ul>
          </footer>
        </div>
      </article>
    </div>
  </>
)

export default IndexPage
